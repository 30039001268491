import React, { memo, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { serverURL } from '../constants';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { FormHelperText, TableContainer, Input, InputLabel, FormControl, 
CircularProgress, Checkbox, FormControlLabel, Button, NativeSelect, 
Table, TableBody, TableCell, TableHead, Tooltip, IconButton,
TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  API: {
    background: '#eee',
    padding: 10,
    marginBottom: 10
  },
  input: {
    paddingLeft: 10,
    paddingBottom: 20,
    width: '100%'
  },
  delete: {
    float: "right", 
    marginTop: -50
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}  

const Prospects = memo(props => {
    const classes = useStyles();
    const [state, setState] = React.useState({
      pipeline: 0
    });
    const [pipedrive, setPipedrive] = React.useState(null);
    const [pandadoc, setPandadoc] = useState(null);
    const [pipelines, setPipelines] = useState(null);
    const [deals, setDeals] = useState(null);

    const [alertMessage, setAlertMessage] = React.useState("Success");
    const [severity, setSeverity] = React.useState("success");
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = useState(0);

    console.log("pipeline",state.pipeline);
    //console.log("panda",pandadoc);

    useEffect(() => {
      if(pipedrive!==null && pipelines===null)
        getPipelines();
    },[pipedrive]);

    useEffect(() => {
      if(pipelines!==null)
        getDeals();
    },[state.pipeline, pipelines]);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const handleChange = (event) => {
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
    };

    const getAPI = () => {
      axios({
        method: 'get',
        url: serverURL+'/api/apis',
        params: {
          access_token: props.authToken
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/api/apis",response);

          const pipedrive = response.find(el => el.type == "pipedrive");
          if(pipedrive){
            console.log("setPipedrive");
            setPipedrive(pipedrive);

          } else { setPipedrive({}); }

          const pandadoc = response.find(el => el.type == "pandadoc");
          if(pandadoc){
            console.log("setPandadoc");
            setPandadoc(pandadoc);
          } else { setPandadoc({}); }
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    const getPipelines = () => {
      console.log("pipedrive",pipedrive);
      console.log("URL",pipedrive.URL);
      axios({
        method: 'get',
        url: pipedrive.URL+'/v1/pipelines',
        params: {
          api_token: pipedrive.token
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/v1/pipelines",response);
          if(!response.data) setPipelines([]);
            else setPipelines(response.data);
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    const getDeals = () => {
      console.log("pipedrive",pipedrive);
      console.log("URL",pipedrive.URL);
      axios({
        method: 'get',
        url: pipedrive.URL+'/v1/pipelines/'+state.pipeline+'/deals',
        params: {
          api_token: pipedrive.token,
          limit: 1000
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/v1/pipelines/"+state.pipeline+'/deals',response);
          if(!response.data) setDeals([]);
            else setDeals(response.data);
        }
      }).catch(function (error) {
        setDeals([]);
        console.log("err",error);
      });
    };

    useEffect(() => {
      getAPI();
    }, []);

    useEffect(() => {
      getAPI();
    }, [refresh]);

    return(
        <TableContainer component={Paper} style={{padding: 20}}>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
            </Alert>
          </Snackbar>
          <h2>Prospects from PipeDrive</h2>
          { pipelines === null &&
            <CircularProgress />
          }
          { pipelines !== null &&
            <FormControl className={classes.input}>
              <InputLabel htmlFor="age-native-helper">Pipeline</InputLabel>
              <NativeSelect
                value={state.pipeline}
                onChange={handleChange}
                inputProps={{
                  name: 'pipeline',
                  id: 'age-native-helper',
                }}
              >
                <option aria-label="None" />
                {pipelines.map((text, index) => (
                  <option key={index} value={text.id}> {text.id} - {text.name}</option>
                ))}
              </NativeSelect>
              <FormHelperText>Select the pipeline from the list</FormHelperText>
            </FormControl>
          }
          { deals===null && 
            <CircularProgress />
          }
          { deals!==null && 
          <MaterialTable
            title={"Deal list"}
            columns={[
              { title: 'ID', field: 'id', type: 'numeric' },
              { title: 'Name', field: 'title' },
              { title: 'Organization', field: 'org_name' },
              { title: 'Owner', field: 'owner_name' },
              { title: 'Update Date', field: 'update_time', removable: true },
              { title: 'Stage', field: 'stage_order_nr', removable: true}
            ]}
            data={deals}
            icons={tableIcons}
            actions={[
              {
                icon: tableIcons.Search,
                tooltip: 'View on Pipedrive',
                onClick: (event, rowData) => {
                  window.open(pipedrive.URL+"deal/"+rowData.id,'_blank');
                }
              },
              {
                icon: tableIcons.Add,
                tooltip: 'Add to clients',
                onClick: (event, rowData) => {
                  axios({
                    method: 'put',
                    url: serverURL+'/api/clients',
                    data: {
                      name: rowData.org_name,
                      type: 'prospect',
                      DealID: rowData.id,
                      lastUpdate: new Date().toISOString()
                    },
                    params: {
                      access_token: props.authToken
                    }
                  }).then(function (resp) {
                    if(resp.status == 200){
                      setSeverity("success");
                      setAlertMessage("Saved");
                      setOpen(true);
                      props.onAdd();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err",error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  })
                }
              }
            ]}
            options={{
              pageSize: 10
            }}
          />
          }
        </TableContainer>
    )
});

export default Prospects;