import React, { memo, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, lighten, withStyles } from '@material-ui/core/styles';
import { serverURL } from '../constants';
import { logs } from '../App';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { green } from '@material-ui/core/colors';

import { FormHelperText, TableContainer, Input, InputLabel, FormControl, 
CircularProgress, Checkbox, FormControlLabel, Button, NativeSelect, 
Table, TableBody, TableCell, TableHead, Tooltip, IconButton,
Select, MenuItem } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Document from '@material-ui/icons/Description';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MailIcon from '@material-ui/icons/Mail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ExitToApp: forwardRef((props, ref) => <ExitToApp {...props} ref={ref} />),
    PersonAddIcon: forwardRef((props, ref) => <PersonAddIcon {...props} ref={ref} />)
  };

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  block: {
    background: '#eee',
    padding: 10,
    marginBottom: 10
  },
  input: {
    paddingLeft: 10,
    paddingBottom: 20,
    width: '100%'
  },
  delete: {
    float: "right", 
    marginTop: -50
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const PandaButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const Clients = memo(props => {
    const classes = useStyles();
    const [pipedrive, setPipedrive] = React.useState(null);
    const [pandadoc, setPandadoc] = useState(null);
    const [seats, setSeats] = useState([]);
    const [license, setLicense] = useState([]);
    const [showMail, setShowMail] = useState(false);

    const [alertMessage, setAlertMessage] = React.useState("Success");
    const [severity, setSeverity] = React.useState("success");
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [ID, setID] = useState(null);
    const [type, setType] = useState(props.type);

    const handleChange = (event) => {
      axios({
        method: 'put',
        url: serverURL+'/api/clients',
        params: {
          access_token: props.authToken
        },
        data: {
          id: props.id,
          name: props.name,
          type: event.target.value,
          DealID: props.DealID,
          lastUpdate: new Date().toISOString()
        }
      }).then(function (resp) {
        if(resp.status == 200){
          setSeverity("success");
          setAlertMessage("Saved");
          setOpen(true);
        } else {
        }
        console.log("resp",resp);
      }).catch(function (error) {
      });
      props.onUpdate();
      setType(event.target.value);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const getAPI = () => {
      axios({
        method: 'get',
        url: serverURL+'/api/apis',
        params: {
          access_token: props.authToken
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/api/apis",response);

          const pipedrive = response.find(el => el.type == "pipedrive");
          if(pipedrive){
            setPipedrive(pipedrive);
          } else { setPipedrive({}); }

          const pandadoc = response.find(el => el.type == "pandadoc");
          if(pandadoc){
            setPandadoc(pandadoc);
          } else { setPandadoc({}); }
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    const getLicense = () => {
      axios({
        method: 'get',
        url: serverURL+'/api/licenses',
        params: {
          access_token: props.authToken,
          filter: {"where":{"clientID":props.id}}
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/api/licenses",response);
          setLicense(response.reverse());
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    const getSeats = () => {
      axios({
        method: 'get',
        url: serverURL+'/api/seats',
        params: {
          access_token: props.authToken,
          filter: {"where":{"clientID":props.id}}
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/api/seats",response);
          setSeats(response);
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    const getActualLicense = (license) => {
      console.log("license is",license);
      if(!license.length) return 'no license';
      if( new Date(license[0].endDate) > new Date() ){
        return license[0].type;
      } else {
        return "expired";
      }
    };

    useEffect(() => {
      getAPI();
      getLicense();
      getSeats();
    }, []);

    if(ID===null || ID!=props.id){
      setType(props.type);
      setID(props.id);
      setLicense(null);
      setSeats(null);
      getAPI();
      getLicense();
      getSeats();
    }

    console.log("pandadocuments",props.pandadocuments);
    //console.log("lic",license);
    //console.log("sea",seats);

    return(
        <TableContainer component={Paper} style={{padding: 20}}>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
            </Alert>
          </Snackbar>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={classes.paper}>
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">react-transition-group animates me.</p>
              </div>
            </Fade>
          </Modal>
          <h2>{props.name}</h2>
          { props.DealID && props.DealID>0 &&
          <FormControl style={{position: 'absolute', right: 55, marginTop: -50}}>
              <Button variant="contained" onClick={() => {
                if(window.confirm('Delete this client ? This is irrevocable.')) {
                  console.log("confirm");
                  axios({
                    method: 'delete',
                    url: serverURL+'/api/clients/'+props.id,
                    params: {
                      access_token: props.authToken
                    }
                  }).then(function (resp) {
                    if(resp.status == 200){
                      setSeverity("success");
                      setAlertMessage("Saved");
                      setOpen(true);
                      logs("delete","client Name:"+props.name+" dealID:"+props.DealID+" type:"+props.type);
                      props.onDel();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                    console.log("resp",resp);
                  }).catch(function (error) {
                    console.log("err",error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  })
                }
                else 
                {
                  console.log("else");
                }
              }}><DeleteIcon /> Delete</Button>
            </FormControl>
          }
          <div className={classes.block}>
            <h3>Overview</h3>
            <div style={{flexDirection: 'row', flex: 1}}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{minWidth: 150}}
                  value={type}
                  onChange={handleChange}
                >
                  <MenuItem value={"prospect"}>Prospect</MenuItem>
                  <MenuItem value={"client"}>Client</MenuItem>
                </Select>
              </FormControl>
              
              { license!==null &&
              <span style={{display: 'inline-block'}}>
                <div style={{fontSize: 10, color: '#666'}}>Actual License</div>
                {getActualLicense(license)==="expired"||getActualLicense(license)==="no license"?<ClearIcon/>:<DoneIcon/>}
                <span style={{fontSize: 25, fontWeight: 'bold', color: getActualLicense(license)==="expired"?'red':(getActualLicense(license)==="no license"?"#888":"green") }}>{getActualLicense(license)}</span>
              </span>
              }
              
            </div>
            {props.trial&&
            <div>
              Trial : <span style={{color: 'green', fontWeight: 'bold'}}>{props.trial} days</span>
            </div>
            }
            {props.email&&
            <div>
              MAIL : <span style={{color: 'green', fontWeight: 'bold'}}>{props.email}</span>
            </div>
            }
            {props.phone&&
            <div>
              PHONE : <span style={{color: 'green', fontWeight: 'bold'}}>{props.phone}</span>
            </div>
            }
            {/*<div>
              Expiration date : <span style={{color: 'red', fontWeight: 'bold'}}>2020-05-21 (13 days)</span>
            </div>*/}
            { props.DealID && props.DealID>0 &&
            <FormControl style={{padding: 7}}>
              <Button variant="contained" color="primary" onClick={() => {
                window.open(pipedrive.URL+"deal/"+props.DealID,'_blank');
              }}><Search /> View in Pipedrive (DEAL #{props.DealID})</Button>
            </FormControl>
            }
            {props.pandadocuments.map((doc, index) => (
              <FormControl key={index} style={{padding: 7}}>
                <PandaButton variant="contained" color="primary" onClick={() => {
                  window.open("https://app.pandadoc.com/a/#/document/v1/editor/"+doc.id+"/content",'_blank');
                }}><Document /> {doc.name}</PandaButton>
              </FormControl>
            ))}
          </div>
          { license===null &&
            <CircularProgress disableShrink />
          }
          { license!==null &&
          <MaterialTable
            title="License Manager"
            columns={[
              { title: 'Start Date', field: 'startDate', cellStyle: { minWidth: 125 }, type: 'date' },
              { title: 'End Date', field: 'endDate', cellStyle: { minWidth: 125 }, type: 'date', editable: 'never'},
              { title: 'Duration (days)', field: 'daysDuration', type: 'numeric' },
              { title: 'License', field: 'type', lookup: { 'trial': 'Trial', 'starter': 'Starter', 'pro': 'Pro', 'infinite': 'Infinite' }, initialEditValue: 'trial' },
              { title: 'Amount', field: 'amount', type: 'numeric' },
              { title: 'Due', field: 'due', type: 'numeric' }
            ]}
            data={license?license:[]}
            /*[
              { start: '2020-05-06', end: '2020-05-21', duration: '15 days', license: 'trial', amount: 0, due: 0 }
            ]*/
            icons={{...tableIcons,
              Add: forwardRef((props, ref) => <button style={{padding: 2}}><AddBox {...props} ref={ref} style={{ fontSize: 35, color: 'green' }} /><div style={{fontSize: 16}}>Add License</div></button>)
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let startDate = new Date(newData.startDate);
                    startDate.setDate(startDate.getDate() + Number.parseInt(newData.daysDuration)); //newData.daysDuration
                    let endDate = startDate.toISOString();
                    console.log(endDate);
                    console.log("put /api/licenses",{
                      clientID: props.id,
                      startDate: newData.startDate,
                      endDate: endDate,
                      daysDuration: newData.daysDuration,
                      type: newData.type,
                      amount: newData.amount,
                      due: newData.due,
                      createDate: new Date().toISOString()
                    });
                    axios({
                      method: 'put',
                      url: serverURL+'/api/licenses',
                      params: {
                        access_token: props.authToken
                      },
                      data: {
                        clientID: props.id,
                        startDate: newData.startDate,
                        endDate: endDate,
                        daysDuration: newData.daysDuration,
                        type: newData.type,
                        amount: newData.amount,
                        due: newData.due,
                        createDate: new Date().toISOString()
                      }
                    }).then(function (resp) {
                      if(resp.status == 200){
                        const response = resp.data;
                        console.log("/api/licenses",response);
                        setSeverity("success");
                        setAlertMessage("Saved");
                        setOpen(true);
                        getLicense();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let startDate = new Date(newData.startDate);
                    startDate.setDate(startDate.getDate() + Number.parseInt(newData.daysDuration)); //newData.daysDuration
                    let endDate = startDate.toISOString();
                    axios({
                      method: 'post',
                      url: serverURL+'/api/licenses/'+oldData.id+"/replace",
                      params: {
                        access_token: props.authToken
                      },
                      data: {
                        clientID: props.id,
                        startDate: newData.startDate,
                        endDate: endDate,
                        daysDuration: newData.daysDuration,
                        type: newData.type,
                        amount: newData.amount,
                        due: newData.due
                      }
                    }).then(function (resp) {
                      if(resp.status == 200){
                        const response = resp.data;
                        console.log("put /api/licenses/"+oldData.id+"/replace",response);
                        setSeverity("success");
                        setAlertMessage("Saved");
                        logs("update",'license '+JSON.stringify(oldData));
                        setOpen(true);
                        getLicense();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    });
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    console.log("delete:",oldData);
                    axios({
                      method: 'delete',
                      url: serverURL+'/api/licenses/'+oldData.id,
                      params: {
                        access_token: props.authToken
                      }
                    }).then(function (resp) {
                      if(resp.status == 200){
                        setSeverity("success");
                        setAlertMessage("Saved");
                        logs("delete",'license '+JSON.stringify(oldData));
                        setOpen(true);
                        getLicense();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    });
                  }, 600);
                }),
            }}
          />
          }
          <br/>
          {/* seats===null &&
            <CircularProgress disableShrink />
            =========================================================================================================================================================================================
            =========================================================================================================================================================================================
          */}
          { seats!==null &&
          <MaterialTable
            title="Seats Manager"
            columns={[
              { title: 'Firstname', field: 'firstname' },
              { title: 'Lastname', field: 'lastname' },
              { title: 'Email', field: 'email', render: rowData => <input type="text" value={rowData.email} style={{fontFamily: 'monospace', width: showMail?400:'auto', position: showMail?'absolute':'relative', marginTop: -11}} onMouseOver={()=>{setShowMail(true);}} onMouseOut={()=>{setShowMail(false);}}/> },
              { title: 'Password', field: 'password', render: rowData => <input type="text" value={rowData.password} style={{fontFamily: 'monospace'}}/>, initialEditValue: Math.random().toString(36).substring(5) },
              { title: 'Type', field: 'type', type: 'numeric', lookup: { 'admin': 'Admin', 'broker': 'Broker', 'user': 'User', 'disabled': 'Disabled'}, initialEditValue: 'user' },
              {
                title: 'Access',
                field: 'access',
                lookup: { 'corporate': 'Corporate', 'individual': 'Individual', 'individual+corporate': 'Corporate + Individual' },
                initialEditValue: 'individual+corporate'
              },
              {
                title: 'Status',
                render: rowData => 
                  <span>
                  { rowData &&
                  <span>
                    <Tooltip title={rowData.individualStatus?new Date(rowData.individualStatus).toDateString():"Not Created"}>
                      <span style={{color: rowData.individualStatus?'green':'red', display: rowData.access=='corporate'?'none':'flex', fontSize: 9}}><AccountBoxIcon/>IND</span> 
                    </Tooltip>
                    <Tooltip title={rowData.CorporateStatus?new Date(rowData.CorporateStatus).toDateString():"Not Created"}>
                      <span style={{color: rowData.CorporateStatus?'green':'red', display: rowData.access=='individual'?'none':'flex', fontSize: 9}}><AccountBoxIcon/>CORP</span>
                    </Tooltip>
                    <Tooltip title={rowData.mailStatus?new Date(rowData.mailStatus).toDateString():"Not Sent"}>
                      <span style={{color: rowData.mailStatus?'green':'red', display: 'flex', fontSize: 9}}><MailIcon/></span>
                    </Tooltip>
                  </span>
                  }
                  </span>,
                editable: 'never'
              }
            ]}
            data={seats?seats:[]}
            icons={{...tableIcons,
              Add: forwardRef((props, ref) => <button style={{padding: 2}}><AddBox {...props} ref={ref} style={{ fontSize: 35, color: 'green' }} /><div style={{fontSize: 16}}>Add Seat</div></button>)
            }}
            actions={[
              rowData => ({
                icon: tableIcons.ExitToApp,
                tooltip: 'Open ThinkInsurtech Broker',
                onClick: (event, rowData) => {
                  axios({
                    method: 'post',
                    url: 'https://individuals.thinkinsurtech.com/api/Users/Impersonate',
                    params: {
                      goldKey: 'cXxAxrY5Q2NellI1g3y75aMWPiJBtnXO',
                      email: rowData.email
                    }
                  }).then(function (resp) {
                    if(resp.status == 200){
                      const response = resp.data;
                      console.log("POST /api/Users/Impersonate",response);
                      window.open('https://individuals.thinkinsurtech.com/','_blank');
                    }
                  }).catch(function (error) {
                    console.log("err",error);
                  });
                }
              }),
              rowData => ({
                icon: MailIcon,
                tooltip: 'Send Logins to '+rowData.email,
                onClick: () => {

                  axios({
                    method: 'post',
                    url: 'https://api.eu.mailgun.net/v3/mg.thinkinsurtech.com/messages',
                    auth: {
                      username: 'api',
                      password: '2528cfc6cd47912baaac7fe13f0ccbb1-1053eade-9d8b5402'
                    },
                    params: {
                      from: 'Team Think Insurtech <team@thinkinsurtech.com>',
                      to: rowData.email,
                      subject: 'Welcome to ThinkInsurtech SaaS dedicated to Health Insurance Brokers!',
                      html: `<p>
                        <a href='thinkinsurtech.com'>
                        <img src='https://thinkinsurtech.com/sites/default/files/Think_Insurtech_new_approach_Saas_health_insurance.png' />
                        </a>
                        <br><br>
                        Dear ${rowData.firstname},<br>
                        <br>
                        This email contains important information about your free access trial. Check out our <a href='https://thinkinsurtech.com/quickstart-guide-tutorial-individuals'>Quick Start Guide Tutorial</a> and how to’s.<br>
                        <br>
                        To access Think Insurtech platforms:<br>
                        <br><br>
                        ${rowData.access==='individual'||rowData.access==='individual+corporate'?`
                        • Go to <a href='https://individuals.thinkinsurtech.com/dashboard'>https://individuals.thinkinsurtech.com/dashboard</a> to access Individuals platform<br>
                        <ul>
                          <li>Login using <span style="font-family: monospace; font-size: 18px;"><strong>${rowData.email}</strong></span></li>
                          <li>Your password is <span style="font-family: monospace; font-size: 18px;"><strong>${rowData.password}</strong></span></li>
                        </ul>
                        <br>`:''}
                        ${rowData.access==='corporate'||rowData.access==='individual+corporate'?`
                        • Go to <a href='https://broker.thinkinsurtech.com/dashboard'>https://broker.thinkinsurtech.com/dashboard</a> to access Corporate platform<br>
                        <ul>
                          <li>Login using <span style="font-family: monospace; font-size: 18px;"><strong>${rowData.email}</strong></span></li>
                          <li>Your password is <span style="font-family: monospace; font-size: 18px;"><strong>${rowData.password}</strong></span></li>
                        </ul>
                        <br>`:''}
                        <br>
                        ${rowData.access==='individual+corporate'?`
                        You will have access to both platforms:<br>
                        - ThinkInsurtech Individual<br>
                        - ThinkInsurtech Corporate<br>
                        <br>`:''}
                        If you encounter a problem, contact our technical support <a href='mailto:support@thinkinsurtech.com'>support@thinkinsurtech.com</a> - Tel: <strong>+33 1 76 21 10 65</strong> or the think insurtech team <a href='mailto:team@thinkinsurtech.com'>team@thinkinsurtech.com</a> - Tel : <strong>+33 1 76 21 10 69</strong> .<br>
                        <br>
                        Please recommend our services and post your reviews on Google Review. We offer you 20% discount on our Saas Edition  - Download our <a href='https://thinkinsurtech.com/referral-partner-agreement'>Refferal partner agreement</a> and send us by email.<br>
                        We remain at your disposal for any questions, comparisons in relation to our SaaS Think Insurtech platform representing 32 players and 3 Master Broker agreement in the international health insurance market,<br>
                        <br>
                        See How we manage Data the flow of Data to be compliance with IDD & GDPR? Download our <a href='https://thinkinsurtech.com/blog/Data-Process-Flow-Insurance-Broker-Medical-Health-Insurance'>Data process scheme</a><br>
                        <br>
                        Follow our news feed on <a href='https://twitter.com/thinkinsurtech'>Twitter</a> and <a href='https://www.linkedin.com/in/international-health-insurance-saas-platform-457065190/'>Linkedin</a><br>
                        <br>
                        Download our brochure in PDF form: <a href='https://thinkinsurtech.com/sites/default/files/Think-Insurtech_Brochure_2020.pdf'>Think Insurtech SaaS</a><br>
                        <br>
                        <br>
                        Best Regards,<br>
                        Think Insurtech Team<br>
                        <br>
                        <a href='thinkinsurtech.com'>
                          <img src='https://thinkinsurtech.com/sites/default/files/logo-transparent-think_0.png' />
                        </a>
                      </p>`
                    }
                  }).then(function (resp) {
                    console.log("resp",resp);
                    if(resp.status == 200){
                      setSeverity("success");
                      setAlertMessage("MAIL "+resp.data.message);

                      axios({
                        method: 'patch',
                        url: serverURL+'/api/seats/'+rowData.id,
                        params: {
                          access_token: props.authToken
                        },
                        data: {
                          clientID: rowData.clientID,
                          mailStatus: new Date().toISOString()
                        }
                      }).then(function (resp) {
                        if(resp.status == 200){
                          const response = resp.data;
                          console.log("put /api/seats/"+rowData.id,response);

                          getSeats();
                        }
                      }).catch(function (error) {
                        console.log("err",error);
                      });

                      setOpen(true);
                    }else{
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err",error.message);
                    setSeverity("error");
                    setAlertMessage("MAIL: "+error.message);
                    setOpen(true);
                  });

                  //window.open('https://individuals.thinkinsurtech.com/','_blank');
                }
              }),
              rowData => ({
                icon: tableIcons.PersonAddIcon,
                tooltip: 'Create Login/Password on ThinkInsurtech '+rowData.access,
                onClick: () => {
                  console.log(rowData);
                  if(rowData.access=="individual+corporate"||rowData.access=="individual")
                    axios({
                      method: 'post',
                      url: 'https://individuals.thinkinsurtech.com/api/Users',
                      data: {
                        "email": rowData.email,
                        "password": rowData.password,
                        "username": rowData.email,
                        "userType": "user",
                        "enabled": 1
                      }
                    }).then(function (resp) {
                      console.log('https://individuals.thinkinsurtech.com/api/Users');
                      console.log("resp",resp);
                      if(resp.status == 200){
                        setSeverity("success");
                        setAlertMessage("INDIVIDUAL ACCOUNT CREATED");
                        setOpen(true);
                        
                        axios({
                          method: 'patch',
                          url: serverURL+'/api/seats/'+rowData.id,
                          params: {
                            access_token: props.authToken
                          },
                          data: {
                            clientID: rowData.clientID,
                            individualStatus: new Date().toISOString()
                          }
                        }).then(function (resp) {
                          if(resp.status == 200){
                            const response = resp.data;
                            console.log("put /api/seats/"+rowData.id,response);

                            getSeats();
                          }
                        }).catch(function (error) {
                          console.log("err",error);
                        });

                        getLicense();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error.message);
                      setSeverity("error");
                      setAlertMessage("INDIVIDUAL: "+error.message+"\nAccount already exist?");
                      setOpen(true);
                    });

                  if(rowData.access=="individual+corporate"||rowData.access=="corporate"){
                    let time = 0;
                    if(rowData.access=="individual+corporate") time = 1500;
                    sleep(time).then(() => {
                      axios({
                        method: 'post',
                        url: 'https://backend.thinkinsurtech.com/api/People',
                        data: {
                          "email": rowData.email,
                          "password": rowData.password,
                          "firstName": rowData.firstname,
                          "lastName": rowData.lastname,
                          "username": rowData.email,
                          "type": "Broker",
                          "enabled": 1
                        }
                      }).then(function (resp) {
                        console.log('https://backend.thinkinsurtech.com/api/People');
                        console.log("resp",resp);
                        if(resp.status == 200){
                          setSeverity("success");
                          setAlertMessage("CORPORATE ACCOUNT CREATED");
                          setOpen(true);

                          axios({
                            method: 'patch',
                            url: serverURL+'/api/seats/'+rowData.id,
                            params: {
                              access_token: props.authToken
                            },
                            data: {
                              clientID: rowData.clientID,
                              CorporateStatus: new Date().toISOString()
                            }
                          }).then(function (resp) {
                            if(resp.status == 200){
                              const response = resp.data;
                              console.log("put /api/seats/"+rowData.id,response);
  
                              getSeats();
                            }
                          }).catch(function (error) {
                            console.log("err",error);
                          });

                          getLicense();
                        }else{
                          setSeverity("error");
                          setAlertMessage("error");
                          setOpen(true);
                        }
                      }).catch(function (error) {
                        console.log("err",error.message);
                        setSeverity("error");
                        setAlertMessage("CORPORATE: "+error.message+"\nAccount already exist?");
                        setOpen(true);
                      });
                    });
                  }
                }
              })
            ]}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    console.log("newData",newData);
                    let data = seats;
                    data.push(newData);
                    setSeats(data);
                    console.log("put /api/seats");
                    axios({
                      method: 'put',
                      url: serverURL+'/api/seats',
                      params: {
                        access_token: props.authToken
                      },
                      data: {
                        clientID: props.id,
                        lastname: newData.lastname,
                        firstname: newData.firstname,
                        type: newData.type,
                        access: newData.access,
                        password: newData.password,
                        email: newData.email,
                        createdDate: new Date().toISOString()
                      }
                    }).then(function (resp) {
                      if(resp.status == 200){
                        const response = resp.data;
                        console.log("/api/seats",response);
                        setSeverity("success");
                        setAlertMessage("Saved");
                        setOpen(true);
                        getSeats();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    axios({
                      method: 'post',
                      url: serverURL+'/api/seats/'+oldData.id+"/replace",
                      params: {
                        access_token: props.authToken
                      },
                      data: {
                        clientID: props.id,
                        lastname: newData.lastname,
                        firstname: newData.firstname,
                        type: newData.type,
                        access: newData.access,
                        password: newData.password,
                        email: newData.email,
                        createdDate: oldData.createdDate,
                        updateDate: new Date().toISOString(),
                        individualStatus: oldData.individualStatus,
                        CorporateStatus: oldData.CorporateStatus,
                        mailStatus: oldData.mailStatus
                      }
                    }).then(function (resp) {
                      if(resp.status == 200){
                        const response = resp.data;
                        console.log("put /api/seats/"+oldData.id+"/replace",response);
                        setSeverity("success");
                        setAlertMessage("Saved");
                        logs("update",'seat '+JSON.stringify(oldData));
                        setOpen(true);
                        getSeats();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    });
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    console.log("delete:",oldData);
                    axios({
                      method: 'delete',
                      url: serverURL+'/api/seats/'+oldData.id,
                      params: {
                        access_token: props.authToken
                      }
                    }).then(function (resp) {
                      if(resp.status == 200){
                        setSeverity("success");
                        setAlertMessage("Saved");
                        setOpen(true);
                        logs("delete",'seat '+JSON.stringify(oldData));
                        getSeats();
                      }else{
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    });
                  }, 600);
                }),
            }}
          />
          }

        </TableContainer>
    )
});

export default Clients;