import React, { useState, forwardRef, useEffect, memo } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import LeftMenu from './components/LeftMenu'
import RightMenu from './components/RightMenu'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { serverURL } from './constants';
import axios from 'axios';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, PieChart, Pie, Sector, Cell, Legend } from 'recharts';

import Login from './components/login';
import Api from './components/Api';
import Prospects from './components/Prospects';
import Clients from './components/Clients';
// TODO: REFACTOR

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EuroIcon from '@material-ui/icons/Euro';
import PayIcon from '@material-ui/icons/Payment';
import DashIcon from '@material-ui/icons/Dashboard';
import APIIcon from '@material-ui/icons/SettingsInputComponent';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Tooltip, Collapse, Box, Button, ListItemAvatar, Avatar, SwipeableDrawer, Grid, InputAdornment,
  FormControl, InputLabel, Select } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Cached';
import WorkIcon from '@material-ui/icons/Work';
import ImageIcon from '@material-ui/icons/Image';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import ChatIcon from '@material-ui/icons/Chat';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import MaterialTable from 'material-table';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FolderIcon from '@material-ui/icons/Folder';
import MailIcon from '@material-ui/icons/Mail';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

var ip = require('ip');
//const fs = require('fs');
//const readline = require('readline');
//const {google} = require('googleapis');

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const drawerWidth = 220;
const toolbarMargin = 50;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'linear-gradient(to top, #ff0024, #ff4e4f, #ff7776, #ff9a9b, #ffbbbd)'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: toolbarMargin,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    marginTop: toolbarMargin,
    padding: theme.spacing(3),
  }
}));

var _email = null;
var _token = null;
export function logs(action, element){
  console.log("token:", _token);
  console.log("login:", _email);
  if(_token)
  axios({
      method: 'post',
      url: serverURL+'/api/logs',
      params: {
        access_token: _token
      },
      data: {
        date: new Date().toISOString(),
        login: _email,
        action: action,
        element: element,
        IP: ip.address()
      }
    }).then(function (resp) {
      if(resp.status == 200){
        const response = resp.data;
        console.log("/api/logs",resp);
      }else{

      }
    }).catch(function (error) {
      console.log("err logs",error);
    });
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
  price,
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props: row) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell align="right">...</TableCell>
                    <TableCell align="right">More</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {"message"}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function FolderItem(props: row) {
  const { row, view, dark } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
    <ListItem className="element" style={{width: view?300:'100%'}} onClick={()=>{}}>
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Vacation" secondary="July 20, 2014" />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send notification" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <DraftsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send mail only" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send mobile only" />
        </StyledMenuItem>
      </StyledMenu>
    </ListItem>
  );
}

const App = memo(props => {
  const classes = useStyles();
  const [authToken, setAuthToken] = useState(null);
  const [email, setEmail] = useState("-");
  const [selectedURL, setSelectedURL] = useState(null);
  const [status, setStatus] = useState(0);
  const [license, setLicense] = useState(null);
  const [seats, setSeats] = useState(null);
  const [page, setPage] = useState("Files List");
  const [broker, setBroker] = useState({"name": "", "id": 0});
  const [brokersList, setBrokersList] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [view, setView] = useState(true);
  const [cloud, setCloud] = useState(0);

  const rows = [
    createData('Support Mail', '12-12-2020', 'support@thinkinsurtech.com', 'support@thinkinsurtech.com', 'GOOGLE MAIL'),
    createData('Metting', '12-12-2020', 'support@thinkinsurtech.com', 'support@thinkinsurtech.com', 'GOOGLE MAIL'),
  ];

  const rows2 = [
    createData('Assurance Meeting', '12-12-2020', 'https://meet.google.com/bub-jsbg', 'support@thinkinsurtech.com', 'GOOGLE CALENDAR'),
    createData('React Meeting', '12-12-2020', 'https://meet.google.com/bub-jsbg', 'support@thinkinsurtech.com', 'GOOGLE CALENDAR'),
  ];

  const logout = () => {
    document.cookie = "access_token="; 
    document.cookie = "email="; 
    document.location='';
  };

  const handleChange = (event) => {
    setSearch(event.target.value.toUpperCase());
  }

  /*const getPandaDocuments = () => {
    axios({
      method: 'get',
      url: 'https://api.pandadoc.com/public/v1/documents',
      headers: {
        Authorization: 'API-Key '+pandadoc.token
      }
    }).then(function (resp) {
      if(resp.status == 200){
        console.log("get PandaDoc /documents",resp.data.results);
        setPandadocuments(resp.data.results);
      } else {

      }
    }).catch(function (error) {

    });
  };

  useEffect(()=>{
    if(pandadoc!==null)
      getPandaDocuments();
  }, [pandadoc]);*/

  useEffect(()=>{
    if(authToken!==null){

      _token = authToken;
      _email = email;
    }
  },[authToken]);

  /*if(!authToken){
    return(<Login 
    onToken={(token) => { 
      setAuthToken(token); 
    }}
    onEmail={(email) => { 
      setEmail(email); 
    }}
    />);
  }*/

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            <a href="/">
              <img alt="logo" src={require('./images/logo.png')} style={{height: 100, padding: 3}}/>
              <span style={{position: 'absolute', fontSize: 18, top: 32, color: "#fff", textShadow: "1px 2px 2px #666"}}>THINK File Manager</span>
            </a>
            <span style={{fontSize: 12, color: '#ccc'}}>{email} • <a style={{textDecoration: 'underline', color: '#fff', cursor: 'pointer'}} onClick={()=>{logout();}}>logout</a></span>
          </Typography>
          <span style={{position: 'absolute', right: 10}}>
            <Tooltip title={"12 new messages"}>
              <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={12} color="secondary">
                  <ChatIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </span>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {['Files List','Chat','Emails','Video Conference','Settings'].map((text, index) => (
              <ListItem button key={text} onClick={() => { setPage(text); }} selected={page==text?true:false}>
                <ListItemIcon>
                  {index===0&&<FolderIcon />}
                  {index===1&&<ChatIcon />}
                  {index===2&&<MailIcon />}
                  {index===3&&<VoiceChatIcon />}
                  {index===4&&<APIIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <div style={{margin: 40}}></div>
        </div>
      </Drawer>
      {/* ======================================================================================================================= */}
      <main className={classes.content}>
        <Toolbar />
        { page == "Files List" &&
        <div>
          <TextField 
          id="filled-search" 
          label="Search" 
          type="search" 
          margin="dense"
          onChange={handleChange}
          style={{width: '60%', margin: 5}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined" />
          <FormControl variant="outlined" className={classes.formControl} style={{width: '30%', margin: 5}} margin="dense">
            <InputLabel id="demo-simple-select-outlined-label">Cloud</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={cloud}
              onChange={handleChange}
              label="Cloud"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>Google Drive + CRM (Pipedrive)</MenuItem>
              <MenuItem value={1}>Google Drive</MenuItem>
              <MenuItem value={2}>CRM (Pipedrive)</MenuItem>
            </Select>
          </FormControl>
          <Tooltip title={"Change view list"}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={()=>setView(!view)}
            >
              {view?<ViewModuleIcon/>:<ViewListIcon />}
            </IconButton>
          </Tooltip>
          <List style={{
            width: '100%',
            display: 'flex',
            flexWrap: "wrap",
            //maxWidth: 600,
            //maxWidth: 420,
          }}>
            {[...Array(30)].map((x, i) =><FolderItem view={view} dark={i%2}/>)}
          </List>
          <Button variant="contained"><AddIcon /> Create Folder</Button>
        </div>
        }
        { page == "Settings" &&
          <Api authToken={authToken} />
        }
        { page === "Chat" &&
        <div>
          <Paper style={{marginBottom: 15, padding: 5}}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>B</Avatar>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="h6" gutterBottom>
                  Brian Piper
                </Typography>
                <Typography noWrap>{"Fantastic platform!"}</Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{marginBottom: 15, padding: 5}}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>W</Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" gutterBottom>
                  Werner Walter
                </Typography>
                <Typography>{"Amazing!!"}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
        }
        { page === "Emails" &&
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Title</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Sender</TableCell>
                  <TableCell align="right">Receiver</TableCell>
                  <TableCell align="right">Source</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        }
        { page === "Video Conference" &&
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Meeting Name</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Link</TableCell>
                  <TableCell align="right">Sender</TableCell>
                  <TableCell align="right">Source</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        }
      </main>
      {/* ====================================================================================================================== */}
      <SwipeableDrawer
      anchor={'right'}
      open={openMenu}
      onClose={()=>setOpenMenu(false)}
      onOpen={()=>setOpenMenu(true)}
      >
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </div>
  );
});

export default App;
