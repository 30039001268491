import React, { memo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { serversList } from '../constants';

const useStyles = makeStyles(theme => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    }
}));

const StatusIndicator = ({ status }) => {
    switch(status){
        case 0: return <span style={{color: '#f22', fontWeight: 'bold'}}>Not Connected</span>;
        case 1: return <span style={{color: '#ff2', fontWeight: 'bold'}}>Connecting...</span>;
        case 2: return <span style={{color: '#2f2', fontWeight: 'bold'}}>Connected</span>;
        case 3: return <span style={{color: '#f52', fontWeight: 'bold'}}>Error - Not Connected</span>;
        default:
        return <span>...</span>;
    }
};

const NavBar = memo(props => {
    const classes = useStyles();

    return(
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Typography /*variant="title"*/ noWrap>
                    <img alt="logo" src={require('../images/logo.png')} style={{height: 100, padding: 3}}/>
                    <span style={{position: 'absolute', fontSize: 18, top: 32}}> Administration Interface</span>
                </Typography>
            </Toolbar> 
    <span style={{right: 0, position: 'fixed', float: 'right', padding: 15}}>Status: <StatusIndicator status={props.status?props.status:0} /></span>
    <span style={{right: 0, top: 17, position: 'fixed', float: 'right', padding: 15, fontSize: 9}}>{serversList[props.selectedURL]}</span>
        </AppBar>
    )
});

export default NavBar;