import React, { memo, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { serverURL } from '../constants';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { FormHelperText, TableContainer, Input, InputLabel, FormControl, 
CircularProgress, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { logs } from '../App';

const useStyles = makeStyles(theme => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    API: {
      background: '#eee',
      padding: 10,
      marginBottom: 10
    },
    input: {
      paddingLeft: 10,
      paddingBottom: 20,
      width: '100%'
    },
    delete: {
      float: "right", 
      marginTop: -50
    }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}  

const Api = memo(props => {
    const classes = useStyles();
    const [pipedrive, setPipedrive] = React.useState(null);
    const [pandadoc, setPandadoc] = useState(null);

    const [alertMessage, setAlertMessage] = React.useState("Success");
    const [severity, setSeverity] = React.useState("success");
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = useState(0);

    console.log("pipe",pipedrive);
    console.log("panda",pandadoc);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const getAPI = () => {
      axios({
        method: 'get',
        url: serverURL+'/api/apis',
        params: {
          access_token: props.authToken
        }
      }).then(function (resp) {
        if(resp.status == 200){
          const response = resp.data;
          console.log("/api/apis",response);

          const pipedrive = response.find(el => el.type == "pipedrive");
          if(pipedrive){
            console.log("setPipedrive");
            setPipedrive(pipedrive);
          } else { setPipedrive({}); }

          const pandadoc = response.find(el => el.type == "pandadoc");
          if(pandadoc){
            console.log("setPandadoc");
            setPandadoc(pandadoc);
          } else { setPandadoc({}); }
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    useEffect(() => {
      getAPI();
    }, []);

    useEffect(() => {
      getAPI();
    }, [refresh]);

    return(
        <TableContainer component={Paper} style={{padding: 20}}>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
            </Alert>
          </Snackbar>
          <h2>Settings</h2>
        <div className={classes.API}>
          <h3>Google Drive / Google File Stream</h3>
        </div>
        <div className={classes.API}>
          <h3>Pipedrive</h3>
          { pipedrive == null &&
            <CircularProgress />
          }
          { pipedrive != null && pipedrive.type == undefined &&
            <Button variant="contained" onClick={() => {
              setPipedrive(null);
              axios({
                method: 'put',
                url: serverURL+'/api/apis',
                data: {
                  URL: 'https://',
                  enabled: 1,
                  token: '',
                  type: 'pipedrive',
                  lastUpdate: new Date().toISOString()
                },
                params: {
                  access_token: props.authToken
                }
              }).then(function (resp) {
                if(resp.status == 200){
                  setSeverity("success");
                  setAlertMessage("Saved");
                  setOpen(true);
                  setRefresh(refresh+1);
                } else {
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                }
              }).catch(function (error) {
                console.log("err",error);
                setSeverity("error");
                setAlertMessage("error");
                setOpen(true);
              })
            }}><AddBoxIcon/> Create configuration</Button>
          }
          { pipedrive != null && pipedrive.type != undefined &&
          <span>
            { pipedrive.lastUpdate &&
              <span style={{fontSize: 9, color: '#666', float: 'right'}}>Last Update {pipedrive.lastUpdate}</span>
            }
            <FormControl className={classes.input}>
              <InputLabel htmlFor="component">API URL</InputLabel>
              <Input id="component" value={pipedrive.URL} style={{width: '100%'}} onChange={ (evt) => {
                setPipedrive({
                  URL: evt.target.value,
                  enabled: pipedrive.enabled,
                  id: pipedrive.id,
                  token: pipedrive.token,
                  type: pipedrive.type,
                  lastUpdate: new Date().toISOString()
                });
              }} />
              <FormHelperText style={{color: 'red'}} hidden={pipedrive.URL.includes(".pipedrive.com")?true:false}>Should be like : https://YOUR_BROKER_NAME_HERE.pipedrive.com</FormHelperText>
            </FormControl>
            <span style={{width: '100%', flexDirection: 'column'}}>
              <FormControl className={classes.input}>
                <InputLabel htmlFor="component">Account Token</InputLabel>
                <Input id="component" value={pipedrive.token} onChange={ (evt) => {
                setPipedrive({
                  URL: pipedrive.URL,
                  enabled: pipedrive.enabled,
                  id: pipedrive.id,
                  token: evt.target.value,
                  type: pipedrive.type,
                  lastUpdate: new Date().toISOString()
                });
              }} />
              </FormControl>
              <Button variant="contained" style={{position: 'absolute', right: 50}} onClick={() => {
                window.open(pipedrive.URL+"/settings/api",'_blank');
              }} disabled={pipedrive.URL.includes(".pipedrive.com")?false:true}><SettingsIcon /> Get token</Button>
            </span>
            <FormControlLabel
              className={classes.input}
              control={<Checkbox checked={Boolean(pipedrive.enabled)} onClick={() => {
                setPipedrive({
                  URL: pipedrive.URL,
                  enabled: Number(!pipedrive.enabled),
                  id: pipedrive.id,
                  token: pipedrive.token,
                  type: pipedrive.type,
                  lastUpdate: new Date().toISOString()
                });
              }} name="enabled" color="primary" />}
              label="Enabled"
            />
            <FormControl className={classes.delete}>
              <Button variant="contained" onClick={() => {
                axios({
                  method: 'delete',
                  url: serverURL+'/api/apis/'+pipedrive.id,
                  params: {
                    access_token: props.authToken
                  }
                }).then(function (resp) {
                  if(resp.status == 200){
                    setSeverity("success");
                    setAlertMessage("Saved");
                    setOpen(true);
                    logs("delete","pipedrive API");
                    setPipedrive({});
                  } else {
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                    setPipedrive({});
                  }
                  console.log("resp",resp);
                }).catch(function (error) {
                  console.log("err",error);
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                  setPipedrive({});
                })
              }}><DeleteIcon /> Delete configuration</Button>
            </FormControl>
            <FormControl className={classes.input}>
              <Button variant="contained" color="primary" onClick={() => {
                 axios({
                  method: 'put',
                  url: serverURL+'/api/apis',
                  data: pipedrive,
                  params: {
                    access_token: props.authToken
                  }
                }).then(function (resp) {
                  if(resp.status == 200){
                    setSeverity("success");
                    logs("update","pipedrive API");
                    setAlertMessage("Saved");
                    setOpen(true);

                  } else {
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  }
                  console.log("resp",resp);
                }).catch(function (error) {
                  console.log("err",error);
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                });
              }}><SaveAltIcon /> Save</Button>
            </FormControl>
          </span>
          }
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div className={classes.API}>
          <h3>PandaDoc</h3>
          { pandadoc == null &&
            <CircularProgress />
          }
          { pandadoc != null && pandadoc.type == undefined &&
            <Button variant="contained" onClick={() => {
              setPandadoc(null);
              axios({
                method: 'put',
                url: serverURL+'/api/apis',
                data: {
                  URL: 'https://',
                  enabled: 1,
                  token: '',
                  type: 'pandadoc',
                  lastUpdate: new Date().toISOString()
                },
                params: {
                  access_token: props.authToken
                }
              }).then(function (resp) {
                if(resp.status == 200){
                  setSeverity("success");
                  setAlertMessage("Saved");
                  setOpen(true);
                  setRefresh(refresh+1);
                } else {
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                }
              }).catch(function (error) {
                console.log("err",error);
                setSeverity("error");
                setAlertMessage("error");
                setOpen(true);
              })
            }}><AddBoxIcon/> Create configuration</Button>
          }
          { pandadoc != null && pandadoc.type != undefined &&
          <span>
            { pandadoc.lastUpdate &&
              <span style={{fontSize: 9, color: '#666', float: 'right'}}>Last Update {pandadoc.lastUpdate}</span>
            }
                        <span style={{width: '100%', flexDirection: 'column'}}>
              <FormControl className={classes.input}>
                <InputLabel htmlFor="component">Account Token</InputLabel>
                <Input id="component" value={pandadoc.token} onChange={ (evt) => {
                setPandadoc({
                  URL: pandadoc.URL,
                  enabled: pandadoc.enabled,
                  id: pandadoc.id,
                  token: evt.target.value,
                  type: pandadoc.type,
                  lastUpdate: new Date().toISOString()
                });
              }} />
              </FormControl>
              <Button variant="contained" style={{position: 'absolute', right: 50}} onClick={() => {
                window.open("https://app.pandadoc.com/a/#/settings/integrations/api/",'_blank');
              }}><SettingsIcon /> Get token</Button>
            </span>
            <FormControlLabel
              className={classes.input}
              control={<Checkbox checked={Boolean(pandadoc.enabled)} onClick={() => {
                setPandadoc({
                  URL: pandadoc.URL,
                  enabled: Number(!pandadoc.enabled),
                  id: pandadoc.id,
                  token: pandadoc.token,
                  type: pandadoc.type,
                  lastUpdate: new Date().toISOString()
                });
              }} name="enabled" color="primary" />}
              label="Enabled"
            />
            <FormControl className={classes.delete}>
              <Button variant="contained" onClick={() => {
                axios({
                  method: 'delete',
                  url: serverURL+'/api/apis/'+pandadoc.id,
                  params: {
                    access_token: props.authToken
                  }
                }).then(function (resp) {
                  if(resp.status == 200){
                    setSeverity("success");
                    setAlertMessage("Saved");
                    setOpen(true);
                    logs("delete","Pandadoc API");
                    setPandadoc({});
                  } else {
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                    setPandadoc({});
                  }
                  console.log("resp",resp);
                }).catch(function (error) {
                  console.log("err",error);
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                  setPandadoc({});
                })
              }}><DeleteIcon /> Delete configuration</Button>
            </FormControl>
            <FormControl className={classes.input}>
              <Button variant="contained" color="primary" onClick={() => {
                 axios({
                  method: 'put',
                  url: serverURL+'/api/apis',
                  data: pandadoc,
                  params: {
                    access_token: props.authToken
                  }
                }).then(function (resp) {
                  if(resp.status == 200){
                    setSeverity("success");
                    logs("update","Pandadoc API");
                    setAlertMessage("Saved");
                    setOpen(true);

                  } else {
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  }
                  console.log("resp",resp);
                }).catch(function (error) {
                  console.log("err",error);
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                });
              }}><SaveAltIcon /> Save</Button>
            </FormControl>
          </span>
          }
        </div>
        {/* ------------------------------------------------------------------------------------------- */} 
        <div className={classes.API}>
          <h3>Backend</h3>
            
          <FormControl disabled className={classes.input}>
            <InputLabel htmlFor="component-disabled">URL</InputLabel>
            <Input id="component-disabled" value={serverURL} style={{width: '100%'}} />
            <FormHelperText>Disabled</FormHelperText>
          </FormControl>

          <FormControl disabled className={classes.input}>
            <InputLabel htmlFor="component-disabled">Token (Autogenerated)</InputLabel>
            <Input id="component-disabled" value={props.authToken} style={{width: '100%'}} />
            <FormHelperText>Disabled</FormHelperText>
          </FormControl>
        </div>
      </TableContainer>
    )
});

export default Api;